/* Import variables START */
/*$project-form-control-background-color-v1:					$color-light-gray;
		$project-form-control-background-color-v1-AUTOFILL:			$project-form-control-background-color-v1;
		$project-form-control-background-color-v1-FOCUS:			$color-light-gray;
		$project-form-control-background-color-v1-ERROR:			$color-light-gray;
		$project-form-control-background-color-v1-DISABLED:			$color-light-gray;*/
/*$project-form-control-border-color-v1:						$color-gray;
		$project-form-control-border-color-v1-FOCUS:				$color-gray;
		$project-form-control-border-color-v1-ERROR:				$color-red;
		$project-form-control-border-color-v1-DISABLED:				$color-gray;*/
/*$project-form-control-border-radius-v1:						$project-default-border-radius;
		$project-form-control-border-radius-v1-SMALL:				$project-form-control-border-radius-v1 - 5;
		$project-form-control-border-radius-v1-LARGE:				$project-form-control-border-radius-v1 + 5;*/
/*$project-form-control-border-width-v1:						1px;
		$project-form-control-border-width-v1-SMALL:				$project-form-control-border-width-v1;
		$project-form-control-border-width-v1-LARGE:				$project-form-control-border-width-v1;*/
/*$project-form-control-color-v1:								$color-gray;
		$project-form-control-color-v1-AUTOFILL:					$project-form-control-color-v1;
		$project-form-control-color-v1-FOCUS:						$color-black;
		$project-form-control-color-v1-ERROR:						$color-red;
		$project-form-control-color-v1-DISABLED:					$color-dark-gray;

		$project-form-control-color-v1-placeholder:					$color-gray;
		$project-form-control-color-v1-placeholder-FOCUS:			$color-black;
		$project-form-control-color-v1-placeholder-ERROR:			$color-red;
		$project-form-control-color-v1-placeholder-DISABLED:		$color-dark-gray;*/
/*$project-form-control-font-size-v1:							$project-default-font-size - 2;
		$project-form-control-font-size-v1-SMALL:					$project-form-control-font-size-v1 - 2;
		$project-form-control-font-size-v1-LARGE:					$project-form-control-font-size-v1 + 2;*/
/*$project-form-control-height-v1: 							$project-button-height-v1;
		$project-form-control-height-v1-SMALL: 						$project-button-height-v1-SMALL;
		$project-form-control-height-v1-LARGE: 						$project-button-height-v1-LARGE;*/
/*$project-form-control-line-height-v1:						$project-form-control-height-v1;
		$project-form-control-line-height-v1-SMALL:					$project-form-control-height-v1-SMALL;
		$project-form-control-line-height-v1-LARGE:					$project-form-control-height-v1-LARGE;*/
/*$project-form-control-padding-v1:							0 15px;
		$project-form-control-padding-v1-SMALL:						0 10px;
		$project-form-control-padding-v1-LARGE:						0 20px;*/
/*$project-form-control-padding-textarea-v1:					10px 15px;
		$project-form-control-padding-textarea-v1-SMALL:			 5px 10px;
		$project-form-control-padding-textarea-v1-LARGE:			15px 20px;*/
/*$project-select-skin-background-image-v1:					'../img/icons/select-skin-arrow-v1.svg';
		$project-select-skin-background-size-x-v1:					14px;
		$project-select-skin-background-size-y-v1:					auto;
		$project-select-skin-background-size-x-v1-SMALL:			12px;
		$project-select-skin-background-size-y-v1-SMALL:			auto;
		$project-select-skin-background-size-x-v1-LARGE:			16px;
		$project-select-skin-background-size-y-v1-LARGE:			auto;
		$project-select-skin-background-position-v1:				22px;
		$project-select-skin-background-position-v1-SMALL:			20px;
		$project-select-skin-background-position-v1-LARGE:			26px;*/
/*$project-checkradio-background-color-v1:					$color-yellow;
		$project-checkradio-background-color-v1-FOCUS:				$color-orange;
		$project-checkradio-background-color-v1-CHECKED:			$color-yellow;
		$project-checkradio-background-color-v1-ERROR:				$color-yellow;
		$project-checkradio-background-color-v1-DISABLED:			$color-white;*/
/*$project-checkradio-border-color-v1:						$color-green;
		$project-checkradio-border-color-v1-FOCUS:					$color-dark-green;
		$project-checkradio-border-color-v1-CHECKED:				$color-dark-green;
		$project-checkradio-border-color-v1-ERROR:					$color-dark-red;
		$project-checkradio-border-color-v1-DISABLED:				$color-light-gray;*/
/*$project-checkradio-inside-color-v1:						$color-dark-green;
		$project-checkradio-inside-color-v1-FOCUS:					$color-green;
		$project-checkradio-inside-color-v1-CHECKED:				$color-green;
		$project-checkradio-inside-color-v1-ERROR:					$color-dark-red;
		$project-checkradio-inside-color-v1-DISABLED:				$color-dark-gray;*/
/*$project-label-color:										$project-default-color;
		$project-label-color-ERROR:									$color-red;
		$project-label-color-DISABLED:								$color-gray;

		// font family
		$project-label-font-family:									$project-default-font-family;

		// font size
		$project-label-font-size:									$project-default-font-size;
		$project-label-font-size-SMALL:								$project-label-font-size - 2;
		$project-label-font-size-LARGE:								$project-label-font-size + 2;

		// height
		$project-label-height:		 								$project-form-control-height;
		$project-label-height-SMALL: 								$project-form-control-height-SMALL;
		$project-label-height-LARGE: 								$project-form-control-height-LARGE;

		// line height
		$project-label-line-height:									$project-label-height;
		$project-label-line-height-SMALL:							$project-label-height-SMALL;
		$project-label-line-height-LARGE:							$project-label-height-LARGE;*/
/*$project-label-color-v1:								$color-green;
			$project-label-color-v1-ERROR:							$color-dark-red;
			$project-label-color-v1-DISABLED:						$color-light-gray;*/
/*$project-label-line-height-v1:							$project-label-height-v1;
			$project-label-line-height-v1-SMALL:					$project-label-height-v1-SMALL;
			$project-label-line-height-v1-LARGE:					$project-label-height-v1-LARGE;*/
/*$project-label-checkradio-color-v1:						$project-label-color-v1;
			$project-label-checkradio-color-v1-ERROR:				$project-label-color-v1-ERROR;
			$project-label-checkradio-color-v1-DISABLED:			$project-label-color-v1-DISABLED;*/
/*$project-label-checkradio-font-size-v1:					$project-default-font-size + 2;
			$project-label-checkradio-font-size-v1-SMALL:			$project-label-checkradio-font-size-v1 - 2;
			$project-label-checkradio-font-size-v1-LARGE:			$project-label-checkradio-font-size-v1 + 2;*/
/*$project-label-checkradio-line-height-v1:				$project-label-checkradio-font-size-v1 + $project-default-line-height-diff;
			$project-label-checkradio-line-height-v1-SMALL:			$project-label-checkradio-font-size-v1-SMALL + $project-default-line-height-diff;
			$project-label-checkradio-line-height-v1-LARGE:			$project-label-checkradio-font-size-v1-LARGE + $project-default-line-height-diff;*/
/*$project-form-text-color-v1: 								$color-gray;
		$project-form-text-color-v1-ERROR:							$color-red;
		$project-form-text-color-v1-DISABLED:						$color-dark-gray;
		$project-form-text-font-family-v1: 							$project-default-font-family;
		$project-form-text-font-size-v1: 							$project-default-font-size;
		$project-form-text-line-height-v1: 							$project-form-text-font-size-v1 + $project-default-line-height-diff;
		$project-form-text-margin-v1: 								10px 0 0 0;*/
/* Import variables END */
/* Import Bootstrap elements START */
/* Import Bootstrap elements END */
/* Import project elements START */
/*** Project mixins ***/
/* mixins START */
/* mixins END */
/*** Project mixins css3 ***/
/* https://github.com/matthieua/sass-css3-mixins */
/* -------------------------------------------------------------
Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets
List of CSS3 Sass Mixins File to be @imported and @included as you need
The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
ENDING css files
note: All CSS3 Properties are being supported by Safari 5
more info: http://www.findmebyip.com/litmus/#css3-properties
Mixins available:
- css3-prefix - arguments: Property, Value
- background-gradient - arguments: Start Color: #3C3C3C, End Color: #999999
- background-radial - arguments: Start Color: #FFFFFF, Start position: 0%, End Color: #000000, End position: 100%
- background-size - arguments: Width: 100%, Height: 100%
- background-opacity - arguments: Color: #000, Opacity: .85
- border-radius - arguments: Radius: 5px
- border-radius-separate - arguments: Top Left: 5px, Top Left: 5px, Bottom Left: 5px, Bottom Right: 5px
- box - arguments: Orientation: horizontal, Pack: center, Align: center
- box-rgba - arguments: R: 60, G: 3, B: 12, Opacity: 0.23, Color: #3C3C3C
- box-shadow - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
- box-sizing - arguments: Type: border-box
- columns - arguments: Count: 3, Gap: 10
- double-borders - arguments: Color One: #3C3C3C, Color Two: #999999, Radius: 0
- flex - arguments: Value: 1
- flip - arguments: ScaleX: -1
- font-face - arguments: Font Family: myFont, File Name: myFont
- opacity - arguments: Opacity: 0.5
- outline radius - arguments: Radius: 5px
- placeholder - arguments: Color: #666666
- resize - arguments: Direction: both
- rotate - arguments: Degree: 0, M11: 0, M12: 0, M21: 0, M22: 0
CSS Matrix Rotation Calculator http://www.boogdesign.com/examples/transforms/matrix-calculator.html
- text-shadow - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
- transform - arguments: Parameters: null
- transform-style - arguments: Style: preserve-3d
- transition - arguments: What: all, Length: 1s, Easing: ease-in-out
- triple-borders - arguments: Color One: #3C3C3C, Color Two: #999999, Color Three: #000000, Radius: 0
- keyframes - arguments: Animation name
- content: Animation css
- animation - arguments: name duration timing-function delay iteration-count direction fill-mode play-state
(http://www.w3schools.com/cssref/css3_pr_animation.asp)
------------------------------------------------------------- */
/*** Project fonts ***/
/* fonts START */
@font-face {
  font-family: "AGaramondPro-Bold";
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.eot");
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.svg#AGaramondPro-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "AGaramondPro-Italic";
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.svg#AGaramondPro-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Light";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Light.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Light.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.svg#Avenir-Light") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Regular";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.svg#Avenir-Next-LT-Pro-Normal-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Regular-Italic";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.svg#Avenir-Next-LT-Pro-Normal-Regular-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Demi";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.svg#Avenir-Next-LT-Pro-Normal-Demi") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Demi-Italic";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.svg#Avenir-Next-LT-Pro-Normal-Demi-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Lora-Regular";
  src: url("../../.././application/themes/base/assets/fonts/Lora-Regular.eot");
  src: url("../../.././application/themes/base/assets/fonts/Lora-Regular.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.svg#Lora-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FontAwesome';
  src: url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.eot");
  src: url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.eot?#iefix") format("embedded-opentype"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.woff") format("woff"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.ttf") format("truetype"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.svg#FontAwesome") format("svg");
  font-weight: normal;
  font-style: normal; }

/* fonts END */
/* Import project elements END */
.menu-open-close {
  -webkit-box-shadow: 0 0 0 transparent;
  -khtml-box-shadow: 0 0 0 transparent;
  -moz-box-shadow: 0 0 0 transparent;
  -ms-box-shadow: 0 0 0 transparent;
  -o-box-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  -webkit-border-top-left-radius: 0;
  -webkit-border-top-right-radius: 0;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-topright: 0;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  -webkit-transition: all 0.25s ease;
  -khtml-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -ms-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  will-change: transition;
  display: block;
  position: relative;
  height: 30px;
  min-width: 30px;
  border: none;
  background-color: transparent;
  padding: 0;
  order: 1;
  margin-top: -3px; }
  @media (min-width: 1200px) {
    .menu-open-close {
      display: none; } }
  .menu-open-close span {
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    -webkit-border-bottom-left-radius: 20px;
    -moz-border-radius-topleft: 20px;
    -moz-border-radius-topright: 20px;
    -moz-border-radius-bottomright: 20px;
    -moz-border-radius-bottomleft: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    -webkit-transition: all 0.25s ease;
    -khtml-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    -ms-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    transition: all 0.25s ease;
    will-change: transition;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    margin-left: -12px;
    height: 4px;
    background-color: #FFFFFF;
    -webkit-transform: translateY(-2px);
    -khtml-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    -ms-transform: translateY(-2px);
    -o-transform: translateY(-2px);
    transform: translateY(-2px); }
    .menu-open-close span.line1 {
      margin-top: -9px; }
    .menu-open-close span.line3 {
      margin-top: 9px; }
  .open-menu .menu-open-close {
    -webkit-border-top-left-radius: 0px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-topleft: 0px;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px; }
    .open-menu .menu-open-close span.line1 {
      -webkit-transform: rotate(135deg);
      -khtml-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
      filter: progid:DXImageTransform.Microsoft.Matrix( M11=0, M12=0, M21=0, M22=0, sizingMethod='auto expand');
      zoom: 1;
      margin-top: 0; }
    .open-menu .menu-open-close span.line2 {
      filter: alpha(opacity=0);
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      -webkit-opacity: 0;
      -khtml-opacity: 0;
      -moz-opacity: 0;
      -ms-opacity: 0;
      -o-opacity: 0;
      opacity: 0;
      width: 2px;
      margin-left: -1px; }
    .open-menu .menu-open-close span.line3 {
      -webkit-transform: rotate(-135deg);
      -khtml-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -ms-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
      filter: progid:DXImageTransform.Microsoft.Matrix( M11=0, M12=0, M21=0, M22=0, sizingMethod='auto expand');
      zoom: 1;
      margin-top: 0; }
  .menu-open-close:focus {
    outline: none; }

/* Import variables START */
/*$project-form-control-background-color-v1:					$color-light-gray;
		$project-form-control-background-color-v1-AUTOFILL:			$project-form-control-background-color-v1;
		$project-form-control-background-color-v1-FOCUS:			$color-light-gray;
		$project-form-control-background-color-v1-ERROR:			$color-light-gray;
		$project-form-control-background-color-v1-DISABLED:			$color-light-gray;*/
/*$project-form-control-border-color-v1:						$color-gray;
		$project-form-control-border-color-v1-FOCUS:				$color-gray;
		$project-form-control-border-color-v1-ERROR:				$color-red;
		$project-form-control-border-color-v1-DISABLED:				$color-gray;*/
/*$project-form-control-border-radius-v1:						$project-default-border-radius;
		$project-form-control-border-radius-v1-SMALL:				$project-form-control-border-radius-v1 - 5;
		$project-form-control-border-radius-v1-LARGE:				$project-form-control-border-radius-v1 + 5;*/
/*$project-form-control-border-width-v1:						1px;
		$project-form-control-border-width-v1-SMALL:				$project-form-control-border-width-v1;
		$project-form-control-border-width-v1-LARGE:				$project-form-control-border-width-v1;*/
/*$project-form-control-color-v1:								$color-gray;
		$project-form-control-color-v1-AUTOFILL:					$project-form-control-color-v1;
		$project-form-control-color-v1-FOCUS:						$color-black;
		$project-form-control-color-v1-ERROR:						$color-red;
		$project-form-control-color-v1-DISABLED:					$color-dark-gray;

		$project-form-control-color-v1-placeholder:					$color-gray;
		$project-form-control-color-v1-placeholder-FOCUS:			$color-black;
		$project-form-control-color-v1-placeholder-ERROR:			$color-red;
		$project-form-control-color-v1-placeholder-DISABLED:		$color-dark-gray;*/
/*$project-form-control-font-size-v1:							$project-default-font-size - 2;
		$project-form-control-font-size-v1-SMALL:					$project-form-control-font-size-v1 - 2;
		$project-form-control-font-size-v1-LARGE:					$project-form-control-font-size-v1 + 2;*/
/*$project-form-control-height-v1: 							$project-button-height-v1;
		$project-form-control-height-v1-SMALL: 						$project-button-height-v1-SMALL;
		$project-form-control-height-v1-LARGE: 						$project-button-height-v1-LARGE;*/
/*$project-form-control-line-height-v1:						$project-form-control-height-v1;
		$project-form-control-line-height-v1-SMALL:					$project-form-control-height-v1-SMALL;
		$project-form-control-line-height-v1-LARGE:					$project-form-control-height-v1-LARGE;*/
/*$project-form-control-padding-v1:							0 15px;
		$project-form-control-padding-v1-SMALL:						0 10px;
		$project-form-control-padding-v1-LARGE:						0 20px;*/
/*$project-form-control-padding-textarea-v1:					10px 15px;
		$project-form-control-padding-textarea-v1-SMALL:			 5px 10px;
		$project-form-control-padding-textarea-v1-LARGE:			15px 20px;*/
/*$project-select-skin-background-image-v1:					'../img/icons/select-skin-arrow-v1.svg';
		$project-select-skin-background-size-x-v1:					14px;
		$project-select-skin-background-size-y-v1:					auto;
		$project-select-skin-background-size-x-v1-SMALL:			12px;
		$project-select-skin-background-size-y-v1-SMALL:			auto;
		$project-select-skin-background-size-x-v1-LARGE:			16px;
		$project-select-skin-background-size-y-v1-LARGE:			auto;
		$project-select-skin-background-position-v1:				22px;
		$project-select-skin-background-position-v1-SMALL:			20px;
		$project-select-skin-background-position-v1-LARGE:			26px;*/
/*$project-checkradio-background-color-v1:					$color-yellow;
		$project-checkradio-background-color-v1-FOCUS:				$color-orange;
		$project-checkradio-background-color-v1-CHECKED:			$color-yellow;
		$project-checkradio-background-color-v1-ERROR:				$color-yellow;
		$project-checkradio-background-color-v1-DISABLED:			$color-white;*/
/*$project-checkradio-border-color-v1:						$color-green;
		$project-checkradio-border-color-v1-FOCUS:					$color-dark-green;
		$project-checkradio-border-color-v1-CHECKED:				$color-dark-green;
		$project-checkradio-border-color-v1-ERROR:					$color-dark-red;
		$project-checkradio-border-color-v1-DISABLED:				$color-light-gray;*/
/*$project-checkradio-inside-color-v1:						$color-dark-green;
		$project-checkradio-inside-color-v1-FOCUS:					$color-green;
		$project-checkradio-inside-color-v1-CHECKED:				$color-green;
		$project-checkradio-inside-color-v1-ERROR:					$color-dark-red;
		$project-checkradio-inside-color-v1-DISABLED:				$color-dark-gray;*/
/*$project-label-color:										$project-default-color;
		$project-label-color-ERROR:									$color-red;
		$project-label-color-DISABLED:								$color-gray;

		// font family
		$project-label-font-family:									$project-default-font-family;

		// font size
		$project-label-font-size:									$project-default-font-size;
		$project-label-font-size-SMALL:								$project-label-font-size - 2;
		$project-label-font-size-LARGE:								$project-label-font-size + 2;

		// height
		$project-label-height:		 								$project-form-control-height;
		$project-label-height-SMALL: 								$project-form-control-height-SMALL;
		$project-label-height-LARGE: 								$project-form-control-height-LARGE;

		// line height
		$project-label-line-height:									$project-label-height;
		$project-label-line-height-SMALL:							$project-label-height-SMALL;
		$project-label-line-height-LARGE:							$project-label-height-LARGE;*/
/*$project-label-color-v1:								$color-green;
			$project-label-color-v1-ERROR:							$color-dark-red;
			$project-label-color-v1-DISABLED:						$color-light-gray;*/
/*$project-label-line-height-v1:							$project-label-height-v1;
			$project-label-line-height-v1-SMALL:					$project-label-height-v1-SMALL;
			$project-label-line-height-v1-LARGE:					$project-label-height-v1-LARGE;*/
/*$project-label-checkradio-color-v1:						$project-label-color-v1;
			$project-label-checkradio-color-v1-ERROR:				$project-label-color-v1-ERROR;
			$project-label-checkradio-color-v1-DISABLED:			$project-label-color-v1-DISABLED;*/
/*$project-label-checkradio-font-size-v1:					$project-default-font-size + 2;
			$project-label-checkradio-font-size-v1-SMALL:			$project-label-checkradio-font-size-v1 - 2;
			$project-label-checkradio-font-size-v1-LARGE:			$project-label-checkradio-font-size-v1 + 2;*/
/*$project-label-checkradio-line-height-v1:				$project-label-checkradio-font-size-v1 + $project-default-line-height-diff;
			$project-label-checkradio-line-height-v1-SMALL:			$project-label-checkradio-font-size-v1-SMALL + $project-default-line-height-diff;
			$project-label-checkradio-line-height-v1-LARGE:			$project-label-checkradio-font-size-v1-LARGE + $project-default-line-height-diff;*/
/*$project-form-text-color-v1: 								$color-gray;
		$project-form-text-color-v1-ERROR:							$color-red;
		$project-form-text-color-v1-DISABLED:						$color-dark-gray;
		$project-form-text-font-family-v1: 							$project-default-font-family;
		$project-form-text-font-size-v1: 							$project-default-font-size;
		$project-form-text-line-height-v1: 							$project-form-text-font-size-v1 + $project-default-line-height-diff;
		$project-form-text-margin-v1: 								10px 0 0 0;*/
/* Import variables END */
/* Import Bootstrap elements START */
/* Import Bootstrap elements END */
/* Import project elements START */
/*** Project mixins ***/
/* mixins START */
/* mixins END */
/*** Project mixins css3 ***/
/* https://github.com/matthieua/sass-css3-mixins */
/* -------------------------------------------------------------
Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets
List of CSS3 Sass Mixins File to be @imported and @included as you need
The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
ENDING css files
note: All CSS3 Properties are being supported by Safari 5
more info: http://www.findmebyip.com/litmus/#css3-properties
Mixins available:
- css3-prefix - arguments: Property, Value
- background-gradient - arguments: Start Color: #3C3C3C, End Color: #999999
- background-radial - arguments: Start Color: #FFFFFF, Start position: 0%, End Color: #000000, End position: 100%
- background-size - arguments: Width: 100%, Height: 100%
- background-opacity - arguments: Color: #000, Opacity: .85
- border-radius - arguments: Radius: 5px
- border-radius-separate - arguments: Top Left: 5px, Top Left: 5px, Bottom Left: 5px, Bottom Right: 5px
- box - arguments: Orientation: horizontal, Pack: center, Align: center
- box-rgba - arguments: R: 60, G: 3, B: 12, Opacity: 0.23, Color: #3C3C3C
- box-shadow - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
- box-sizing - arguments: Type: border-box
- columns - arguments: Count: 3, Gap: 10
- double-borders - arguments: Color One: #3C3C3C, Color Two: #999999, Radius: 0
- flex - arguments: Value: 1
- flip - arguments: ScaleX: -1
- font-face - arguments: Font Family: myFont, File Name: myFont
- opacity - arguments: Opacity: 0.5
- outline radius - arguments: Radius: 5px
- placeholder - arguments: Color: #666666
- resize - arguments: Direction: both
- rotate - arguments: Degree: 0, M11: 0, M12: 0, M21: 0, M22: 0
CSS Matrix Rotation Calculator http://www.boogdesign.com/examples/transforms/matrix-calculator.html
- text-shadow - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
- transform - arguments: Parameters: null
- transform-style - arguments: Style: preserve-3d
- transition - arguments: What: all, Length: 1s, Easing: ease-in-out
- triple-borders - arguments: Color One: #3C3C3C, Color Two: #999999, Color Three: #000000, Radius: 0
- keyframes - arguments: Animation name
- content: Animation css
- animation - arguments: name duration timing-function delay iteration-count direction fill-mode play-state
(http://www.w3schools.com/cssref/css3_pr_animation.asp)
------------------------------------------------------------- */
/*** Project fonts ***/
/* fonts START */
@font-face {
  font-family: "AGaramondPro-Bold";
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.eot");
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.svg#AGaramondPro-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "AGaramondPro-Italic";
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.svg#AGaramondPro-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Light";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Light.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Light.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.svg#Avenir-Light") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Regular";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.svg#Avenir-Next-LT-Pro-Normal-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Regular-Italic";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.svg#Avenir-Next-LT-Pro-Normal-Regular-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Demi";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.svg#Avenir-Next-LT-Pro-Normal-Demi") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Demi-Italic";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.svg#Avenir-Next-LT-Pro-Normal-Demi-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Lora-Regular";
  src: url("../../.././application/themes/base/assets/fonts/Lora-Regular.eot");
  src: url("../../.././application/themes/base/assets/fonts/Lora-Regular.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.svg#Lora-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FontAwesome';
  src: url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.eot");
  src: url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.eot?#iefix") format("embedded-opentype"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.woff") format("woff"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.ttf") format("truetype"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.svg#FontAwesome") format("svg");
  font-weight: normal;
  font-style: normal; }

/* fonts END */
/* Import project elements END */
.main-menu {
  position: relative;
  display: inline-block;
  margin-right: 15px; }
  .main-menu ul {
    display: inline-block;
    margin: 0;
    padding: 0; }
    .main-menu ul li {
      position: relative;
      display: inline-block;
      padding: 0 10px; }
      @media (min-width: 1200px) {
        .main-menu ul li:first-child {
          padding-left: 0; }
        .main-menu ul li:last-child {
          padding-right: 0; } }
      .main-menu ul li a {
        font-family: "Avenir-Next-LT-Pro-Normal-Regular", Arial, Helvetica, sans-serif;
        font-size: 15px;
        line-height: 30px;
        color: #FFFFFF;
        text-transform: uppercase;
        text-decoration: none; }
        @media (min-width: 1200px) {
          .main-menu ul li a:hover, .main-menu ul li a:focus {
            color: #967a7d;
            text-decoration: none;
            filter: alpha(opacity=100);
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
            -webkit-opacity: 1;
            -khtml-opacity: 1;
            -moz-opacity: 1;
            -ms-opacity: 1;
            -o-opacity: 1;
            opacity: 1; } }
      .main-menu ul li .submenu-toggle {
        -webkit-box-shadow: inset 0px 0px 0px transparent;
        -khtml-box-shadow: inset 0px 0px 0px transparent;
        -moz-box-shadow: inset 0px 0px 0px transparent;
        -ms-box-shadow: inset 0px 0px 0px transparent;
        -o-box-shadow: inset 0px 0px 0px transparent;
        box-shadow: inset 0px 0px 0px transparent;
        -webkit-transition: all 0.25s ease;
        -khtml-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        -ms-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
        will-change: transition;
        position: absolute;
        padding: 0;
        border: 0;
        outline: 0;
        cursor: pointer; }
        @media (min-width: 1200px) {
          .main-menu ul li .submenu-toggle {
            display: none; } }
        @media (max-width: 1199px) {
          .main-menu ul li .submenu-toggle {
            position: absolute;
            top: 0;
            right: 0;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            color: #FFFFFF; } }
      .main-menu ul li ul {
        -webkit-transition: all 0.25s ease;
        -khtml-transition: all 0.25s ease;
        -moz-transition: all 0.25s ease;
        -ms-transition: all 0.25s ease;
        -o-transition: all 0.25s ease;
        transition: all 0.25s ease;
        will-change: transition;
        filter: alpha(opacity=0);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        -webkit-opacity: 0;
        -khtml-opacity: 0;
        -moz-opacity: 0;
        -ms-opacity: 0;
        -o-opacity: 0;
        opacity: 0;
        position: absolute;
        top: 29px;
        background-color: #FFFFFF;
        left: -20px;
        padding: 0 !important;
        max-height: 0;
        overflow: hidden;
        display: block;
        width: 300px; }
        @media (max-width: 1199px) {
          .main-menu ul li ul {
            position: relative;
            top: auto;
            left: auto; } }
        .main-menu ul li ul li {
          display: block;
          padding: 0 20px !important;
          line-height: 40px; }
          .main-menu ul li ul li a {
            padding: 0 10px;
            display: block; }
            .main-menu ul li ul li a:after {
              display: none !important; }
      @media (min-width: 1200px) {
        .main-menu ul li:hover ul, .main-menu ul li:focus ul {
          filter: alpha(opacity=100);
          -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
          -webkit-opacity: 1;
          -khtml-opacity: 1;
          -moz-opacity: 1;
          -ms-opacity: 1;
          -o-opacity: 1;
          opacity: 1;
          max-height: 400px;
          padding: 10px 0 !important; } }
      .main-menu ul li.open ul {
        filter: alpha(opacity=100);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        -webkit-opacity: 1;
        -khtml-opacity: 1;
        -moz-opacity: 1;
        -ms-opacity: 1;
        -o-opacity: 1;
        opacity: 1;
        max-height: 400px;
        padding: 10px 0; }
      .main-menu ul li.open .submenu-toggle {
        -webkit-transform: scale(1, -1);
        -khtml-transform: scale(1, -1);
        -moz-transform: scale(1, -1);
        -ms-transform: scale(1, -1);
        -o-transform: scale(1, -1);
        transform: scale(1, -1); }
  @media (max-width: 1199px) {
    .main-menu.menu-with-hamburger {
      -webkit-transition: all 0.25s ease;
      -khtml-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;
      -ms-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      transition: all 0.25s ease;
      will-change: transition;
      filter: alpha(opacity=0);
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      -webkit-opacity: 0;
      -khtml-opacity: 0;
      -moz-opacity: 0;
      -ms-opacity: 0;
      -o-opacity: 0;
      opacity: 0;
      position: absolute;
      top: 53px;
      left: 0;
      width: 100%;
      background-color: #000000;
      max-height: 0;
      overflow: hidden;
      z-index: 5; }
      .open-menu .main-menu.menu-with-hamburger {
        filter: alpha(opacity=100);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        -webkit-opacity: 1;
        -khtml-opacity: 1;
        -moz-opacity: 1;
        -ms-opacity: 1;
        -o-opacity: 1;
        opacity: 1;
        max-height: 500px;
        padding-top: 15px;
        padding-bottom: 15px; }
      .main-menu.menu-with-hamburger ul {
        width: 100%; }
        .main-menu.menu-with-hamburger ul li {
          overflow: hidden;
          width: 100%;
          padding: 0; }
          .main-menu.menu-with-hamburger ul li a {
            display: block;
            text-align: center;
            width: 100%;
            padding: 0 !important;
            line-height: 40px;
            color: #FFFFFF; } }

/* Import variables START */
/*$project-form-control-background-color-v1:					$color-light-gray;
		$project-form-control-background-color-v1-AUTOFILL:			$project-form-control-background-color-v1;
		$project-form-control-background-color-v1-FOCUS:			$color-light-gray;
		$project-form-control-background-color-v1-ERROR:			$color-light-gray;
		$project-form-control-background-color-v1-DISABLED:			$color-light-gray;*/
/*$project-form-control-border-color-v1:						$color-gray;
		$project-form-control-border-color-v1-FOCUS:				$color-gray;
		$project-form-control-border-color-v1-ERROR:				$color-red;
		$project-form-control-border-color-v1-DISABLED:				$color-gray;*/
/*$project-form-control-border-radius-v1:						$project-default-border-radius;
		$project-form-control-border-radius-v1-SMALL:				$project-form-control-border-radius-v1 - 5;
		$project-form-control-border-radius-v1-LARGE:				$project-form-control-border-radius-v1 + 5;*/
/*$project-form-control-border-width-v1:						1px;
		$project-form-control-border-width-v1-SMALL:				$project-form-control-border-width-v1;
		$project-form-control-border-width-v1-LARGE:				$project-form-control-border-width-v1;*/
/*$project-form-control-color-v1:								$color-gray;
		$project-form-control-color-v1-AUTOFILL:					$project-form-control-color-v1;
		$project-form-control-color-v1-FOCUS:						$color-black;
		$project-form-control-color-v1-ERROR:						$color-red;
		$project-form-control-color-v1-DISABLED:					$color-dark-gray;

		$project-form-control-color-v1-placeholder:					$color-gray;
		$project-form-control-color-v1-placeholder-FOCUS:			$color-black;
		$project-form-control-color-v1-placeholder-ERROR:			$color-red;
		$project-form-control-color-v1-placeholder-DISABLED:		$color-dark-gray;*/
/*$project-form-control-font-size-v1:							$project-default-font-size - 2;
		$project-form-control-font-size-v1-SMALL:					$project-form-control-font-size-v1 - 2;
		$project-form-control-font-size-v1-LARGE:					$project-form-control-font-size-v1 + 2;*/
/*$project-form-control-height-v1: 							$project-button-height-v1;
		$project-form-control-height-v1-SMALL: 						$project-button-height-v1-SMALL;
		$project-form-control-height-v1-LARGE: 						$project-button-height-v1-LARGE;*/
/*$project-form-control-line-height-v1:						$project-form-control-height-v1;
		$project-form-control-line-height-v1-SMALL:					$project-form-control-height-v1-SMALL;
		$project-form-control-line-height-v1-LARGE:					$project-form-control-height-v1-LARGE;*/
/*$project-form-control-padding-v1:							0 15px;
		$project-form-control-padding-v1-SMALL:						0 10px;
		$project-form-control-padding-v1-LARGE:						0 20px;*/
/*$project-form-control-padding-textarea-v1:					10px 15px;
		$project-form-control-padding-textarea-v1-SMALL:			 5px 10px;
		$project-form-control-padding-textarea-v1-LARGE:			15px 20px;*/
/*$project-select-skin-background-image-v1:					'../img/icons/select-skin-arrow-v1.svg';
		$project-select-skin-background-size-x-v1:					14px;
		$project-select-skin-background-size-y-v1:					auto;
		$project-select-skin-background-size-x-v1-SMALL:			12px;
		$project-select-skin-background-size-y-v1-SMALL:			auto;
		$project-select-skin-background-size-x-v1-LARGE:			16px;
		$project-select-skin-background-size-y-v1-LARGE:			auto;
		$project-select-skin-background-position-v1:				22px;
		$project-select-skin-background-position-v1-SMALL:			20px;
		$project-select-skin-background-position-v1-LARGE:			26px;*/
/*$project-checkradio-background-color-v1:					$color-yellow;
		$project-checkradio-background-color-v1-FOCUS:				$color-orange;
		$project-checkradio-background-color-v1-CHECKED:			$color-yellow;
		$project-checkradio-background-color-v1-ERROR:				$color-yellow;
		$project-checkradio-background-color-v1-DISABLED:			$color-white;*/
/*$project-checkradio-border-color-v1:						$color-green;
		$project-checkradio-border-color-v1-FOCUS:					$color-dark-green;
		$project-checkradio-border-color-v1-CHECKED:				$color-dark-green;
		$project-checkradio-border-color-v1-ERROR:					$color-dark-red;
		$project-checkradio-border-color-v1-DISABLED:				$color-light-gray;*/
/*$project-checkradio-inside-color-v1:						$color-dark-green;
		$project-checkradio-inside-color-v1-FOCUS:					$color-green;
		$project-checkradio-inside-color-v1-CHECKED:				$color-green;
		$project-checkradio-inside-color-v1-ERROR:					$color-dark-red;
		$project-checkradio-inside-color-v1-DISABLED:				$color-dark-gray;*/
/*$project-label-color:										$project-default-color;
		$project-label-color-ERROR:									$color-red;
		$project-label-color-DISABLED:								$color-gray;

		// font family
		$project-label-font-family:									$project-default-font-family;

		// font size
		$project-label-font-size:									$project-default-font-size;
		$project-label-font-size-SMALL:								$project-label-font-size - 2;
		$project-label-font-size-LARGE:								$project-label-font-size + 2;

		// height
		$project-label-height:		 								$project-form-control-height;
		$project-label-height-SMALL: 								$project-form-control-height-SMALL;
		$project-label-height-LARGE: 								$project-form-control-height-LARGE;

		// line height
		$project-label-line-height:									$project-label-height;
		$project-label-line-height-SMALL:							$project-label-height-SMALL;
		$project-label-line-height-LARGE:							$project-label-height-LARGE;*/
/*$project-label-color-v1:								$color-green;
			$project-label-color-v1-ERROR:							$color-dark-red;
			$project-label-color-v1-DISABLED:						$color-light-gray;*/
/*$project-label-line-height-v1:							$project-label-height-v1;
			$project-label-line-height-v1-SMALL:					$project-label-height-v1-SMALL;
			$project-label-line-height-v1-LARGE:					$project-label-height-v1-LARGE;*/
/*$project-label-checkradio-color-v1:						$project-label-color-v1;
			$project-label-checkradio-color-v1-ERROR:				$project-label-color-v1-ERROR;
			$project-label-checkradio-color-v1-DISABLED:			$project-label-color-v1-DISABLED;*/
/*$project-label-checkradio-font-size-v1:					$project-default-font-size + 2;
			$project-label-checkradio-font-size-v1-SMALL:			$project-label-checkradio-font-size-v1 - 2;
			$project-label-checkradio-font-size-v1-LARGE:			$project-label-checkradio-font-size-v1 + 2;*/
/*$project-label-checkradio-line-height-v1:				$project-label-checkradio-font-size-v1 + $project-default-line-height-diff;
			$project-label-checkradio-line-height-v1-SMALL:			$project-label-checkradio-font-size-v1-SMALL + $project-default-line-height-diff;
			$project-label-checkradio-line-height-v1-LARGE:			$project-label-checkradio-font-size-v1-LARGE + $project-default-line-height-diff;*/
/*$project-form-text-color-v1: 								$color-gray;
		$project-form-text-color-v1-ERROR:							$color-red;
		$project-form-text-color-v1-DISABLED:						$color-dark-gray;
		$project-form-text-font-family-v1: 							$project-default-font-family;
		$project-form-text-font-size-v1: 							$project-default-font-size;
		$project-form-text-line-height-v1: 							$project-form-text-font-size-v1 + $project-default-line-height-diff;
		$project-form-text-margin-v1: 								10px 0 0 0;*/
/* Import variables END */
/* Import Bootstrap elements START */
/* Import Bootstrap elements END */
/* Import project elements START */
/*** Project mixins ***/
/* mixins START */
/* mixins END */
/*** Project mixins css3 ***/
/* https://github.com/matthieua/sass-css3-mixins */
/* -------------------------------------------------------------
Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets
List of CSS3 Sass Mixins File to be @imported and @included as you need
The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
ENDING css files
note: All CSS3 Properties are being supported by Safari 5
more info: http://www.findmebyip.com/litmus/#css3-properties
Mixins available:
- css3-prefix - arguments: Property, Value
- background-gradient - arguments: Start Color: #3C3C3C, End Color: #999999
- background-radial - arguments: Start Color: #FFFFFF, Start position: 0%, End Color: #000000, End position: 100%
- background-size - arguments: Width: 100%, Height: 100%
- background-opacity - arguments: Color: #000, Opacity: .85
- border-radius - arguments: Radius: 5px
- border-radius-separate - arguments: Top Left: 5px, Top Left: 5px, Bottom Left: 5px, Bottom Right: 5px
- box - arguments: Orientation: horizontal, Pack: center, Align: center
- box-rgba - arguments: R: 60, G: 3, B: 12, Opacity: 0.23, Color: #3C3C3C
- box-shadow - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
- box-sizing - arguments: Type: border-box
- columns - arguments: Count: 3, Gap: 10
- double-borders - arguments: Color One: #3C3C3C, Color Two: #999999, Radius: 0
- flex - arguments: Value: 1
- flip - arguments: ScaleX: -1
- font-face - arguments: Font Family: myFont, File Name: myFont
- opacity - arguments: Opacity: 0.5
- outline radius - arguments: Radius: 5px
- placeholder - arguments: Color: #666666
- resize - arguments: Direction: both
- rotate - arguments: Degree: 0, M11: 0, M12: 0, M21: 0, M22: 0
CSS Matrix Rotation Calculator http://www.boogdesign.com/examples/transforms/matrix-calculator.html
- text-shadow - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
- transform - arguments: Parameters: null
- transform-style - arguments: Style: preserve-3d
- transition - arguments: What: all, Length: 1s, Easing: ease-in-out
- triple-borders - arguments: Color One: #3C3C3C, Color Two: #999999, Color Three: #000000, Radius: 0
- keyframes - arguments: Animation name
- content: Animation css
- animation - arguments: name duration timing-function delay iteration-count direction fill-mode play-state
(http://www.w3schools.com/cssref/css3_pr_animation.asp)
------------------------------------------------------------- */
/*** Project fonts ***/
/* fonts START */
@font-face {
  font-family: "AGaramondPro-Bold";
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.eot");
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.svg#AGaramondPro-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "AGaramondPro-Italic";
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.svg#AGaramondPro-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Light";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Light.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Light.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.svg#Avenir-Light") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Regular";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.svg#Avenir-Next-LT-Pro-Normal-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Regular-Italic";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.svg#Avenir-Next-LT-Pro-Normal-Regular-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Demi";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.svg#Avenir-Next-LT-Pro-Normal-Demi") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Demi-Italic";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.svg#Avenir-Next-LT-Pro-Normal-Demi-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Lora-Regular";
  src: url("../../.././application/themes/base/assets/fonts/Lora-Regular.eot");
  src: url("../../.././application/themes/base/assets/fonts/Lora-Regular.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.svg#Lora-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FontAwesome';
  src: url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.eot");
  src: url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.eot?#iefix") format("embedded-opentype"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.woff") format("woff"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.ttf") format("truetype"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.svg#FontAwesome") format("svg");
  font-weight: normal;
  font-style: normal; }

/* fonts END */
/* Import project elements END */
.footer-menu {
  position: relative; }
  @media (min-width: 992px) {
    .footer-menu {
      margin-right: 30px; } }
  @media (max-width: 767px) {
    .footer-menu {
      width: 100%;
      margin-bottom: 30px; } }
  .footer-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 768px) {
      .footer-menu ul {
        justify-content: space-between; } }
    @media (min-width: 576px) {
      .footer-menu ul {
        align-items: center; } }
    @media (max-width: 767px) {
      .footer-menu ul {
        justify-content: center; } }
    .footer-menu ul li {
      position: relative; }
      @media (max-width: 767px) {
        .footer-menu ul li {
          width: 100%;
          text-align: center; } }
      .footer-menu ul li:before {
        display: none; }
      @media (min-width: 1200px) {
        .footer-menu ul li:first-child {
          padding-left: 0; }
        .footer-menu ul li:last-child {
          padding-right: 0; } }
      @media (min-width: 1620px) {
        .footer-menu ul li {
          padding: 0 15px; } }
      @media (min-width: 1200px) and (max-width: 1619px) {
        .footer-menu ul li {
          padding: 0 10px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .footer-menu ul li {
          padding: 0 5px; } }
      @media (max-width: 991px) {
        .footer-menu ul li {
          padding: 0 10px; } }
      .footer-menu ul li a {
        font-family: "Avenir-Next-LT-Pro-Normal-Regular", Arial, Helvetica, sans-serif;
        font-size: 15px;
        line-height: 30px;
        color: #FFFFFF;
        text-transform: uppercase; }
        @media (min-width: 1200px) {
          .footer-menu ul li a {
            position: relative; }
            .footer-menu ul li a:after {
              -webkit-transition: all 0.25s ease;
              -khtml-transition: all 0.25s ease;
              -moz-transition: all 0.25s ease;
              -ms-transition: all 0.25s ease;
              -o-transition: all 0.25s ease;
              transition: all 0.25s ease;
              will-change: transition;
              filter: alpha(opacity=0);
              -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
              -webkit-opacity: 0;
              -khtml-opacity: 0;
              -moz-opacity: 0;
              -ms-opacity: 0;
              -o-opacity: 0;
              opacity: 0;
              content: "";
              display: block;
              position: absolute;
              bottom: -5px;
              left: 50%;
              width: 0;
              height: 2px;
              background-color: #967a7d; }
            .footer-menu ul li a:hover, .footer-menu ul li a:focus {
              text-decoration: none; }
              .footer-menu ul li a:hover:after, .footer-menu ul li a:focus:after {
                filter: alpha(opacity=100);
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                -webkit-opacity: 1;
                -khtml-opacity: 1;
                -moz-opacity: 1;
                -ms-opacity: 1;
                -o-opacity: 1;
                opacity: 1;
                width: 100%;
                left: 0; } }
        @media (min-width: 1200px) {
          .footer-menu ul li a:hover, .footer-menu ul li a:focus {
            color: #e4cdc7; } }

/* Import variables START */
/*$project-form-control-background-color-v1:					$color-light-gray;
		$project-form-control-background-color-v1-AUTOFILL:			$project-form-control-background-color-v1;
		$project-form-control-background-color-v1-FOCUS:			$color-light-gray;
		$project-form-control-background-color-v1-ERROR:			$color-light-gray;
		$project-form-control-background-color-v1-DISABLED:			$color-light-gray;*/
/*$project-form-control-border-color-v1:						$color-gray;
		$project-form-control-border-color-v1-FOCUS:				$color-gray;
		$project-form-control-border-color-v1-ERROR:				$color-red;
		$project-form-control-border-color-v1-DISABLED:				$color-gray;*/
/*$project-form-control-border-radius-v1:						$project-default-border-radius;
		$project-form-control-border-radius-v1-SMALL:				$project-form-control-border-radius-v1 - 5;
		$project-form-control-border-radius-v1-LARGE:				$project-form-control-border-radius-v1 + 5;*/
/*$project-form-control-border-width-v1:						1px;
		$project-form-control-border-width-v1-SMALL:				$project-form-control-border-width-v1;
		$project-form-control-border-width-v1-LARGE:				$project-form-control-border-width-v1;*/
/*$project-form-control-color-v1:								$color-gray;
		$project-form-control-color-v1-AUTOFILL:					$project-form-control-color-v1;
		$project-form-control-color-v1-FOCUS:						$color-black;
		$project-form-control-color-v1-ERROR:						$color-red;
		$project-form-control-color-v1-DISABLED:					$color-dark-gray;

		$project-form-control-color-v1-placeholder:					$color-gray;
		$project-form-control-color-v1-placeholder-FOCUS:			$color-black;
		$project-form-control-color-v1-placeholder-ERROR:			$color-red;
		$project-form-control-color-v1-placeholder-DISABLED:		$color-dark-gray;*/
/*$project-form-control-font-size-v1:							$project-default-font-size - 2;
		$project-form-control-font-size-v1-SMALL:					$project-form-control-font-size-v1 - 2;
		$project-form-control-font-size-v1-LARGE:					$project-form-control-font-size-v1 + 2;*/
/*$project-form-control-height-v1: 							$project-button-height-v1;
		$project-form-control-height-v1-SMALL: 						$project-button-height-v1-SMALL;
		$project-form-control-height-v1-LARGE: 						$project-button-height-v1-LARGE;*/
/*$project-form-control-line-height-v1:						$project-form-control-height-v1;
		$project-form-control-line-height-v1-SMALL:					$project-form-control-height-v1-SMALL;
		$project-form-control-line-height-v1-LARGE:					$project-form-control-height-v1-LARGE;*/
/*$project-form-control-padding-v1:							0 15px;
		$project-form-control-padding-v1-SMALL:						0 10px;
		$project-form-control-padding-v1-LARGE:						0 20px;*/
/*$project-form-control-padding-textarea-v1:					10px 15px;
		$project-form-control-padding-textarea-v1-SMALL:			 5px 10px;
		$project-form-control-padding-textarea-v1-LARGE:			15px 20px;*/
/*$project-select-skin-background-image-v1:					'../img/icons/select-skin-arrow-v1.svg';
		$project-select-skin-background-size-x-v1:					14px;
		$project-select-skin-background-size-y-v1:					auto;
		$project-select-skin-background-size-x-v1-SMALL:			12px;
		$project-select-skin-background-size-y-v1-SMALL:			auto;
		$project-select-skin-background-size-x-v1-LARGE:			16px;
		$project-select-skin-background-size-y-v1-LARGE:			auto;
		$project-select-skin-background-position-v1:				22px;
		$project-select-skin-background-position-v1-SMALL:			20px;
		$project-select-skin-background-position-v1-LARGE:			26px;*/
/*$project-checkradio-background-color-v1:					$color-yellow;
		$project-checkradio-background-color-v1-FOCUS:				$color-orange;
		$project-checkradio-background-color-v1-CHECKED:			$color-yellow;
		$project-checkradio-background-color-v1-ERROR:				$color-yellow;
		$project-checkradio-background-color-v1-DISABLED:			$color-white;*/
/*$project-checkradio-border-color-v1:						$color-green;
		$project-checkradio-border-color-v1-FOCUS:					$color-dark-green;
		$project-checkradio-border-color-v1-CHECKED:				$color-dark-green;
		$project-checkradio-border-color-v1-ERROR:					$color-dark-red;
		$project-checkradio-border-color-v1-DISABLED:				$color-light-gray;*/
/*$project-checkradio-inside-color-v1:						$color-dark-green;
		$project-checkradio-inside-color-v1-FOCUS:					$color-green;
		$project-checkradio-inside-color-v1-CHECKED:				$color-green;
		$project-checkradio-inside-color-v1-ERROR:					$color-dark-red;
		$project-checkradio-inside-color-v1-DISABLED:				$color-dark-gray;*/
/*$project-label-color:										$project-default-color;
		$project-label-color-ERROR:									$color-red;
		$project-label-color-DISABLED:								$color-gray;

		// font family
		$project-label-font-family:									$project-default-font-family;

		// font size
		$project-label-font-size:									$project-default-font-size;
		$project-label-font-size-SMALL:								$project-label-font-size - 2;
		$project-label-font-size-LARGE:								$project-label-font-size + 2;

		// height
		$project-label-height:		 								$project-form-control-height;
		$project-label-height-SMALL: 								$project-form-control-height-SMALL;
		$project-label-height-LARGE: 								$project-form-control-height-LARGE;

		// line height
		$project-label-line-height:									$project-label-height;
		$project-label-line-height-SMALL:							$project-label-height-SMALL;
		$project-label-line-height-LARGE:							$project-label-height-LARGE;*/
/*$project-label-color-v1:								$color-green;
			$project-label-color-v1-ERROR:							$color-dark-red;
			$project-label-color-v1-DISABLED:						$color-light-gray;*/
/*$project-label-line-height-v1:							$project-label-height-v1;
			$project-label-line-height-v1-SMALL:					$project-label-height-v1-SMALL;
			$project-label-line-height-v1-LARGE:					$project-label-height-v1-LARGE;*/
/*$project-label-checkradio-color-v1:						$project-label-color-v1;
			$project-label-checkradio-color-v1-ERROR:				$project-label-color-v1-ERROR;
			$project-label-checkradio-color-v1-DISABLED:			$project-label-color-v1-DISABLED;*/
/*$project-label-checkradio-font-size-v1:					$project-default-font-size + 2;
			$project-label-checkradio-font-size-v1-SMALL:			$project-label-checkradio-font-size-v1 - 2;
			$project-label-checkradio-font-size-v1-LARGE:			$project-label-checkradio-font-size-v1 + 2;*/
/*$project-label-checkradio-line-height-v1:				$project-label-checkradio-font-size-v1 + $project-default-line-height-diff;
			$project-label-checkradio-line-height-v1-SMALL:			$project-label-checkradio-font-size-v1-SMALL + $project-default-line-height-diff;
			$project-label-checkradio-line-height-v1-LARGE:			$project-label-checkradio-font-size-v1-LARGE + $project-default-line-height-diff;*/
/*$project-form-text-color-v1: 								$color-gray;
		$project-form-text-color-v1-ERROR:							$color-red;
		$project-form-text-color-v1-DISABLED:						$color-dark-gray;
		$project-form-text-font-family-v1: 							$project-default-font-family;
		$project-form-text-font-size-v1: 							$project-default-font-size;
		$project-form-text-line-height-v1: 							$project-form-text-font-size-v1 + $project-default-line-height-diff;
		$project-form-text-margin-v1: 								10px 0 0 0;*/
/* Import variables END */
/* Import Bootstrap elements START */
/* Import Bootstrap elements END */
/* Import project elements START */
/*** Project mixins ***/
/* mixins START */
/* mixins END */
/*** Project mixins css3 ***/
/* https://github.com/matthieua/sass-css3-mixins */
/* -------------------------------------------------------------
Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets
List of CSS3 Sass Mixins File to be @imported and @included as you need
The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
ENDING css files
note: All CSS3 Properties are being supported by Safari 5
more info: http://www.findmebyip.com/litmus/#css3-properties
Mixins available:
- css3-prefix - arguments: Property, Value
- background-gradient - arguments: Start Color: #3C3C3C, End Color: #999999
- background-radial - arguments: Start Color: #FFFFFF, Start position: 0%, End Color: #000000, End position: 100%
- background-size - arguments: Width: 100%, Height: 100%
- background-opacity - arguments: Color: #000, Opacity: .85
- border-radius - arguments: Radius: 5px
- border-radius-separate - arguments: Top Left: 5px, Top Left: 5px, Bottom Left: 5px, Bottom Right: 5px
- box - arguments: Orientation: horizontal, Pack: center, Align: center
- box-rgba - arguments: R: 60, G: 3, B: 12, Opacity: 0.23, Color: #3C3C3C
- box-shadow - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
- box-sizing - arguments: Type: border-box
- columns - arguments: Count: 3, Gap: 10
- double-borders - arguments: Color One: #3C3C3C, Color Two: #999999, Radius: 0
- flex - arguments: Value: 1
- flip - arguments: ScaleX: -1
- font-face - arguments: Font Family: myFont, File Name: myFont
- opacity - arguments: Opacity: 0.5
- outline radius - arguments: Radius: 5px
- placeholder - arguments: Color: #666666
- resize - arguments: Direction: both
- rotate - arguments: Degree: 0, M11: 0, M12: 0, M21: 0, M22: 0
CSS Matrix Rotation Calculator http://www.boogdesign.com/examples/transforms/matrix-calculator.html
- text-shadow - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
- transform - arguments: Parameters: null
- transform-style - arguments: Style: preserve-3d
- transition - arguments: What: all, Length: 1s, Easing: ease-in-out
- triple-borders - arguments: Color One: #3C3C3C, Color Two: #999999, Color Three: #000000, Radius: 0
- keyframes - arguments: Animation name
- content: Animation css
- animation - arguments: name duration timing-function delay iteration-count direction fill-mode play-state
(http://www.w3schools.com/cssref/css3_pr_animation.asp)
------------------------------------------------------------- */
/*** Project fonts ***/
/* fonts START */
@font-face {
  font-family: "AGaramondPro-Bold";
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.eot");
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.svg#AGaramondPro-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "AGaramondPro-Italic";
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.svg#AGaramondPro-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Light";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Light.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Light.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.svg#Avenir-Light") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Regular";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.svg#Avenir-Next-LT-Pro-Normal-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Regular-Italic";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.svg#Avenir-Next-LT-Pro-Normal-Regular-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Demi";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.svg#Avenir-Next-LT-Pro-Normal-Demi") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Demi-Italic";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.svg#Avenir-Next-LT-Pro-Normal-Demi-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Lora-Regular";
  src: url("../../.././application/themes/base/assets/fonts/Lora-Regular.eot");
  src: url("../../.././application/themes/base/assets/fonts/Lora-Regular.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.svg#Lora-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FontAwesome';
  src: url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.eot");
  src: url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.eot?#iefix") format("embedded-opentype"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.woff") format("woff"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.ttf") format("truetype"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.svg#FontAwesome") format("svg");
  font-weight: normal;
  font-style: normal; }

/* fonts END */
/* Import project elements END */
.sidebar-menu ul {
  margin: 0;
  padding: 0; }
  .sidebar-menu ul li {
    position: relative;
    list-style: none;
    margin-bottom: 10px; }
    .sidebar-menu ul li .sidebar-menu-toggle {
      -webkit-box-shadow: inset 0px 0px 0px transparent;
      -khtml-box-shadow: inset 0px 0px 0px transparent;
      -moz-box-shadow: inset 0px 0px 0px transparent;
      -ms-box-shadow: inset 0px 0px 0px transparent;
      -o-box-shadow: inset 0px 0px 0px transparent;
      box-shadow: inset 0px 0px 0px transparent;
      -webkit-transition: all 0.25s ease;
      -khtml-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;
      -ms-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      transition: all 0.25s ease;
      will-change: transition;
      position: absolute;
      top: 0;
      right: 0;
      height: 30px;
      width: 30px;
      background-color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      border: 0;
      outline: 0;
      cursor: pointer; }
      .sidebar-menu ul li .sidebar-menu-toggle.open {
        -webkit-transform: scale(1, -1);
        -khtml-transform: scale(1, -1);
        -moz-transform: scale(1, -1);
        -ms-transform: scale(1, -1);
        -o-transform: scale(1, -1);
        transform: scale(1, -1); }
    .sidebar-menu ul li:before {
      display: none; }
    .sidebar-menu ul li a {
      height: 50px; }
      @media (min-width: 1200px) {
        .sidebar-menu ul li a {
          position: relative; }
          .sidebar-menu ul li a:after {
            -webkit-transition: all 0.25s ease;
            -khtml-transition: all 0.25s ease;
            -moz-transition: all 0.25s ease;
            -ms-transition: all 0.25s ease;
            -o-transition: all 0.25s ease;
            transition: all 0.25s ease;
            will-change: transition;
            filter: alpha(opacity=0);
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
            -webkit-opacity: 0;
            -khtml-opacity: 0;
            -moz-opacity: 0;
            -ms-opacity: 0;
            -o-opacity: 0;
            opacity: 0;
            content: "";
            display: block;
            position: absolute;
            bottom: -5px;
            left: 50%;
            width: 0;
            height: 2px;
            background-color: #232442; }
          .sidebar-menu ul li a:hover, .sidebar-menu ul li a:focus {
            text-decoration: none; }
            .sidebar-menu ul li a:hover:after, .sidebar-menu ul li a:focus:after {
              filter: alpha(opacity=100);
              -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
              -webkit-opacity: 1;
              -khtml-opacity: 1;
              -moz-opacity: 1;
              -ms-opacity: 1;
              -o-opacity: 1;
              opacity: 1;
              width: 100%;
              left: 0; } }
    .sidebar-menu ul li ul {
      -webkit-transition: all 0.25s ease;
      -khtml-transition: all 0.25s ease;
      -moz-transition: all 0.25s ease;
      -ms-transition: all 0.25s ease;
      -o-transition: all 0.25s ease;
      transition: all 0.25s ease;
      will-change: transition;
      margin: 0 0 0 30px;
      padding: 0;
      max-height: 0;
      overflow: hidden; }
      .sidebar-menu ul li ul.open {
        max-height: 300px; }

/* Import variables START */
/*$project-form-control-background-color-v1:					$color-light-gray;
		$project-form-control-background-color-v1-AUTOFILL:			$project-form-control-background-color-v1;
		$project-form-control-background-color-v1-FOCUS:			$color-light-gray;
		$project-form-control-background-color-v1-ERROR:			$color-light-gray;
		$project-form-control-background-color-v1-DISABLED:			$color-light-gray;*/
/*$project-form-control-border-color-v1:						$color-gray;
		$project-form-control-border-color-v1-FOCUS:				$color-gray;
		$project-form-control-border-color-v1-ERROR:				$color-red;
		$project-form-control-border-color-v1-DISABLED:				$color-gray;*/
/*$project-form-control-border-radius-v1:						$project-default-border-radius;
		$project-form-control-border-radius-v1-SMALL:				$project-form-control-border-radius-v1 - 5;
		$project-form-control-border-radius-v1-LARGE:				$project-form-control-border-radius-v1 + 5;*/
/*$project-form-control-border-width-v1:						1px;
		$project-form-control-border-width-v1-SMALL:				$project-form-control-border-width-v1;
		$project-form-control-border-width-v1-LARGE:				$project-form-control-border-width-v1;*/
/*$project-form-control-color-v1:								$color-gray;
		$project-form-control-color-v1-AUTOFILL:					$project-form-control-color-v1;
		$project-form-control-color-v1-FOCUS:						$color-black;
		$project-form-control-color-v1-ERROR:						$color-red;
		$project-form-control-color-v1-DISABLED:					$color-dark-gray;

		$project-form-control-color-v1-placeholder:					$color-gray;
		$project-form-control-color-v1-placeholder-FOCUS:			$color-black;
		$project-form-control-color-v1-placeholder-ERROR:			$color-red;
		$project-form-control-color-v1-placeholder-DISABLED:		$color-dark-gray;*/
/*$project-form-control-font-size-v1:							$project-default-font-size - 2;
		$project-form-control-font-size-v1-SMALL:					$project-form-control-font-size-v1 - 2;
		$project-form-control-font-size-v1-LARGE:					$project-form-control-font-size-v1 + 2;*/
/*$project-form-control-height-v1: 							$project-button-height-v1;
		$project-form-control-height-v1-SMALL: 						$project-button-height-v1-SMALL;
		$project-form-control-height-v1-LARGE: 						$project-button-height-v1-LARGE;*/
/*$project-form-control-line-height-v1:						$project-form-control-height-v1;
		$project-form-control-line-height-v1-SMALL:					$project-form-control-height-v1-SMALL;
		$project-form-control-line-height-v1-LARGE:					$project-form-control-height-v1-LARGE;*/
/*$project-form-control-padding-v1:							0 15px;
		$project-form-control-padding-v1-SMALL:						0 10px;
		$project-form-control-padding-v1-LARGE:						0 20px;*/
/*$project-form-control-padding-textarea-v1:					10px 15px;
		$project-form-control-padding-textarea-v1-SMALL:			 5px 10px;
		$project-form-control-padding-textarea-v1-LARGE:			15px 20px;*/
/*$project-select-skin-background-image-v1:					'../img/icons/select-skin-arrow-v1.svg';
		$project-select-skin-background-size-x-v1:					14px;
		$project-select-skin-background-size-y-v1:					auto;
		$project-select-skin-background-size-x-v1-SMALL:			12px;
		$project-select-skin-background-size-y-v1-SMALL:			auto;
		$project-select-skin-background-size-x-v1-LARGE:			16px;
		$project-select-skin-background-size-y-v1-LARGE:			auto;
		$project-select-skin-background-position-v1:				22px;
		$project-select-skin-background-position-v1-SMALL:			20px;
		$project-select-skin-background-position-v1-LARGE:			26px;*/
/*$project-checkradio-background-color-v1:					$color-yellow;
		$project-checkradio-background-color-v1-FOCUS:				$color-orange;
		$project-checkradio-background-color-v1-CHECKED:			$color-yellow;
		$project-checkradio-background-color-v1-ERROR:				$color-yellow;
		$project-checkradio-background-color-v1-DISABLED:			$color-white;*/
/*$project-checkradio-border-color-v1:						$color-green;
		$project-checkradio-border-color-v1-FOCUS:					$color-dark-green;
		$project-checkradio-border-color-v1-CHECKED:				$color-dark-green;
		$project-checkradio-border-color-v1-ERROR:					$color-dark-red;
		$project-checkradio-border-color-v1-DISABLED:				$color-light-gray;*/
/*$project-checkradio-inside-color-v1:						$color-dark-green;
		$project-checkradio-inside-color-v1-FOCUS:					$color-green;
		$project-checkradio-inside-color-v1-CHECKED:				$color-green;
		$project-checkradio-inside-color-v1-ERROR:					$color-dark-red;
		$project-checkradio-inside-color-v1-DISABLED:				$color-dark-gray;*/
/*$project-label-color:										$project-default-color;
		$project-label-color-ERROR:									$color-red;
		$project-label-color-DISABLED:								$color-gray;

		// font family
		$project-label-font-family:									$project-default-font-family;

		// font size
		$project-label-font-size:									$project-default-font-size;
		$project-label-font-size-SMALL:								$project-label-font-size - 2;
		$project-label-font-size-LARGE:								$project-label-font-size + 2;

		// height
		$project-label-height:		 								$project-form-control-height;
		$project-label-height-SMALL: 								$project-form-control-height-SMALL;
		$project-label-height-LARGE: 								$project-form-control-height-LARGE;

		// line height
		$project-label-line-height:									$project-label-height;
		$project-label-line-height-SMALL:							$project-label-height-SMALL;
		$project-label-line-height-LARGE:							$project-label-height-LARGE;*/
/*$project-label-color-v1:								$color-green;
			$project-label-color-v1-ERROR:							$color-dark-red;
			$project-label-color-v1-DISABLED:						$color-light-gray;*/
/*$project-label-line-height-v1:							$project-label-height-v1;
			$project-label-line-height-v1-SMALL:					$project-label-height-v1-SMALL;
			$project-label-line-height-v1-LARGE:					$project-label-height-v1-LARGE;*/
/*$project-label-checkradio-color-v1:						$project-label-color-v1;
			$project-label-checkradio-color-v1-ERROR:				$project-label-color-v1-ERROR;
			$project-label-checkradio-color-v1-DISABLED:			$project-label-color-v1-DISABLED;*/
/*$project-label-checkradio-font-size-v1:					$project-default-font-size + 2;
			$project-label-checkradio-font-size-v1-SMALL:			$project-label-checkradio-font-size-v1 - 2;
			$project-label-checkradio-font-size-v1-LARGE:			$project-label-checkradio-font-size-v1 + 2;*/
/*$project-label-checkradio-line-height-v1:				$project-label-checkradio-font-size-v1 + $project-default-line-height-diff;
			$project-label-checkradio-line-height-v1-SMALL:			$project-label-checkradio-font-size-v1-SMALL + $project-default-line-height-diff;
			$project-label-checkradio-line-height-v1-LARGE:			$project-label-checkradio-font-size-v1-LARGE + $project-default-line-height-diff;*/
/*$project-form-text-color-v1: 								$color-gray;
		$project-form-text-color-v1-ERROR:							$color-red;
		$project-form-text-color-v1-DISABLED:						$color-dark-gray;
		$project-form-text-font-family-v1: 							$project-default-font-family;
		$project-form-text-font-size-v1: 							$project-default-font-size;
		$project-form-text-line-height-v1: 							$project-form-text-font-size-v1 + $project-default-line-height-diff;
		$project-form-text-margin-v1: 								10px 0 0 0;*/
/* Import variables END */
/* Import Bootstrap elements START */
/* Import Bootstrap elements END */
/* Import project elements START */
/*** Project mixins ***/
/* mixins START */
/* mixins END */
/*** Project mixins css3 ***/
/* https://github.com/matthieua/sass-css3-mixins */
/* -------------------------------------------------------------
Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets
List of CSS3 Sass Mixins File to be @imported and @included as you need
The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
ENDING css files
note: All CSS3 Properties are being supported by Safari 5
more info: http://www.findmebyip.com/litmus/#css3-properties
Mixins available:
- css3-prefix - arguments: Property, Value
- background-gradient - arguments: Start Color: #3C3C3C, End Color: #999999
- background-radial - arguments: Start Color: #FFFFFF, Start position: 0%, End Color: #000000, End position: 100%
- background-size - arguments: Width: 100%, Height: 100%
- background-opacity - arguments: Color: #000, Opacity: .85
- border-radius - arguments: Radius: 5px
- border-radius-separate - arguments: Top Left: 5px, Top Left: 5px, Bottom Left: 5px, Bottom Right: 5px
- box - arguments: Orientation: horizontal, Pack: center, Align: center
- box-rgba - arguments: R: 60, G: 3, B: 12, Opacity: 0.23, Color: #3C3C3C
- box-shadow - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
- box-sizing - arguments: Type: border-box
- columns - arguments: Count: 3, Gap: 10
- double-borders - arguments: Color One: #3C3C3C, Color Two: #999999, Radius: 0
- flex - arguments: Value: 1
- flip - arguments: ScaleX: -1
- font-face - arguments: Font Family: myFont, File Name: myFont
- opacity - arguments: Opacity: 0.5
- outline radius - arguments: Radius: 5px
- placeholder - arguments: Color: #666666
- resize - arguments: Direction: both
- rotate - arguments: Degree: 0, M11: 0, M12: 0, M21: 0, M22: 0
CSS Matrix Rotation Calculator http://www.boogdesign.com/examples/transforms/matrix-calculator.html
- text-shadow - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
- transform - arguments: Parameters: null
- transform-style - arguments: Style: preserve-3d
- transition - arguments: What: all, Length: 1s, Easing: ease-in-out
- triple-borders - arguments: Color One: #3C3C3C, Color Two: #999999, Color Three: #000000, Radius: 0
- keyframes - arguments: Animation name
- content: Animation css
- animation - arguments: name duration timing-function delay iteration-count direction fill-mode play-state
(http://www.w3schools.com/cssref/css3_pr_animation.asp)
------------------------------------------------------------- */
/*** Project fonts ***/
/* fonts START */
@font-face {
  font-family: "AGaramondPro-Bold";
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.eot");
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.svg#AGaramondPro-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "AGaramondPro-Italic";
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.svg#AGaramondPro-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Light";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Light.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Light.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.svg#Avenir-Light") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Regular";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.svg#Avenir-Next-LT-Pro-Normal-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Regular-Italic";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.svg#Avenir-Next-LT-Pro-Normal-Regular-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Demi";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.svg#Avenir-Next-LT-Pro-Normal-Demi") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Demi-Italic";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.svg#Avenir-Next-LT-Pro-Normal-Demi-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Lora-Regular";
  src: url("../../.././application/themes/base/assets/fonts/Lora-Regular.eot");
  src: url("../../.././application/themes/base/assets/fonts/Lora-Regular.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.svg#Lora-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FontAwesome';
  src: url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.eot");
  src: url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.eot?#iefix") format("embedded-opentype"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.woff") format("woff"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.ttf") format("truetype"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.svg#FontAwesome") format("svg");
  font-weight: normal;
  font-style: normal; }

/* fonts END */
/* Import project elements END */
.default-menu {
  position: relative;
  display: block;
  text-align: center; }
  .default-menu ul {
    display: inline-block;
    margin: 0;
    padding: 0; }
    .default-menu ul li {
      position: relative;
      display: inline-block;
      padding: 0 10px; }
      @media (min-width: 1200px) {
        .default-menu ul li:first-child {
          padding-left: 0; }
        .default-menu ul li:last-child {
          padding-right: 0; } }
      .default-menu ul li a {
        font-family: "Avenir-Next-LT-Pro-Normal-Regular", Arial, Helvetica, sans-serif;
        font-size: 16px;
        line-height: 30px;
        color: #967a7d; }
        @media (min-width: 1200px) {
          .default-menu ul li a {
            position: relative; }
            .default-menu ul li a:after {
              -webkit-transition: all 0.25s ease;
              -khtml-transition: all 0.25s ease;
              -moz-transition: all 0.25s ease;
              -ms-transition: all 0.25s ease;
              -o-transition: all 0.25s ease;
              transition: all 0.25s ease;
              will-change: transition;
              filter: alpha(opacity=0);
              -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
              -webkit-opacity: 0;
              -khtml-opacity: 0;
              -moz-opacity: 0;
              -ms-opacity: 0;
              -o-opacity: 0;
              opacity: 0;
              content: "";
              display: block;
              position: absolute;
              bottom: -5px;
              left: 50%;
              width: 0;
              height: 2px;
              background-color: #967a7d; }
            .default-menu ul li a:hover, .default-menu ul li a:focus {
              text-decoration: none; }
              .default-menu ul li a:hover:after, .default-menu ul li a:focus:after {
                filter: alpha(opacity=100);
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                -webkit-opacity: 1;
                -khtml-opacity: 1;
                -moz-opacity: 1;
                -ms-opacity: 1;
                -o-opacity: 1;
                opacity: 1;
                width: 100%;
                left: 0; } }
        @media (min-width: 1200px) {
          .default-menu ul li a:hover, .default-menu ul li a:focus {
            color: #967a7d; } }

/* Import variables START */
/*$project-form-control-background-color-v1:					$color-light-gray;
		$project-form-control-background-color-v1-AUTOFILL:			$project-form-control-background-color-v1;
		$project-form-control-background-color-v1-FOCUS:			$color-light-gray;
		$project-form-control-background-color-v1-ERROR:			$color-light-gray;
		$project-form-control-background-color-v1-DISABLED:			$color-light-gray;*/
/*$project-form-control-border-color-v1:						$color-gray;
		$project-form-control-border-color-v1-FOCUS:				$color-gray;
		$project-form-control-border-color-v1-ERROR:				$color-red;
		$project-form-control-border-color-v1-DISABLED:				$color-gray;*/
/*$project-form-control-border-radius-v1:						$project-default-border-radius;
		$project-form-control-border-radius-v1-SMALL:				$project-form-control-border-radius-v1 - 5;
		$project-form-control-border-radius-v1-LARGE:				$project-form-control-border-radius-v1 + 5;*/
/*$project-form-control-border-width-v1:						1px;
		$project-form-control-border-width-v1-SMALL:				$project-form-control-border-width-v1;
		$project-form-control-border-width-v1-LARGE:				$project-form-control-border-width-v1;*/
/*$project-form-control-color-v1:								$color-gray;
		$project-form-control-color-v1-AUTOFILL:					$project-form-control-color-v1;
		$project-form-control-color-v1-FOCUS:						$color-black;
		$project-form-control-color-v1-ERROR:						$color-red;
		$project-form-control-color-v1-DISABLED:					$color-dark-gray;

		$project-form-control-color-v1-placeholder:					$color-gray;
		$project-form-control-color-v1-placeholder-FOCUS:			$color-black;
		$project-form-control-color-v1-placeholder-ERROR:			$color-red;
		$project-form-control-color-v1-placeholder-DISABLED:		$color-dark-gray;*/
/*$project-form-control-font-size-v1:							$project-default-font-size - 2;
		$project-form-control-font-size-v1-SMALL:					$project-form-control-font-size-v1 - 2;
		$project-form-control-font-size-v1-LARGE:					$project-form-control-font-size-v1 + 2;*/
/*$project-form-control-height-v1: 							$project-button-height-v1;
		$project-form-control-height-v1-SMALL: 						$project-button-height-v1-SMALL;
		$project-form-control-height-v1-LARGE: 						$project-button-height-v1-LARGE;*/
/*$project-form-control-line-height-v1:						$project-form-control-height-v1;
		$project-form-control-line-height-v1-SMALL:					$project-form-control-height-v1-SMALL;
		$project-form-control-line-height-v1-LARGE:					$project-form-control-height-v1-LARGE;*/
/*$project-form-control-padding-v1:							0 15px;
		$project-form-control-padding-v1-SMALL:						0 10px;
		$project-form-control-padding-v1-LARGE:						0 20px;*/
/*$project-form-control-padding-textarea-v1:					10px 15px;
		$project-form-control-padding-textarea-v1-SMALL:			 5px 10px;
		$project-form-control-padding-textarea-v1-LARGE:			15px 20px;*/
/*$project-select-skin-background-image-v1:					'../img/icons/select-skin-arrow-v1.svg';
		$project-select-skin-background-size-x-v1:					14px;
		$project-select-skin-background-size-y-v1:					auto;
		$project-select-skin-background-size-x-v1-SMALL:			12px;
		$project-select-skin-background-size-y-v1-SMALL:			auto;
		$project-select-skin-background-size-x-v1-LARGE:			16px;
		$project-select-skin-background-size-y-v1-LARGE:			auto;
		$project-select-skin-background-position-v1:				22px;
		$project-select-skin-background-position-v1-SMALL:			20px;
		$project-select-skin-background-position-v1-LARGE:			26px;*/
/*$project-checkradio-background-color-v1:					$color-yellow;
		$project-checkradio-background-color-v1-FOCUS:				$color-orange;
		$project-checkradio-background-color-v1-CHECKED:			$color-yellow;
		$project-checkradio-background-color-v1-ERROR:				$color-yellow;
		$project-checkradio-background-color-v1-DISABLED:			$color-white;*/
/*$project-checkradio-border-color-v1:						$color-green;
		$project-checkradio-border-color-v1-FOCUS:					$color-dark-green;
		$project-checkradio-border-color-v1-CHECKED:				$color-dark-green;
		$project-checkradio-border-color-v1-ERROR:					$color-dark-red;
		$project-checkradio-border-color-v1-DISABLED:				$color-light-gray;*/
/*$project-checkradio-inside-color-v1:						$color-dark-green;
		$project-checkradio-inside-color-v1-FOCUS:					$color-green;
		$project-checkradio-inside-color-v1-CHECKED:				$color-green;
		$project-checkradio-inside-color-v1-ERROR:					$color-dark-red;
		$project-checkradio-inside-color-v1-DISABLED:				$color-dark-gray;*/
/*$project-label-color:										$project-default-color;
		$project-label-color-ERROR:									$color-red;
		$project-label-color-DISABLED:								$color-gray;

		// font family
		$project-label-font-family:									$project-default-font-family;

		// font size
		$project-label-font-size:									$project-default-font-size;
		$project-label-font-size-SMALL:								$project-label-font-size - 2;
		$project-label-font-size-LARGE:								$project-label-font-size + 2;

		// height
		$project-label-height:		 								$project-form-control-height;
		$project-label-height-SMALL: 								$project-form-control-height-SMALL;
		$project-label-height-LARGE: 								$project-form-control-height-LARGE;

		// line height
		$project-label-line-height:									$project-label-height;
		$project-label-line-height-SMALL:							$project-label-height-SMALL;
		$project-label-line-height-LARGE:							$project-label-height-LARGE;*/
/*$project-label-color-v1:								$color-green;
			$project-label-color-v1-ERROR:							$color-dark-red;
			$project-label-color-v1-DISABLED:						$color-light-gray;*/
/*$project-label-line-height-v1:							$project-label-height-v1;
			$project-label-line-height-v1-SMALL:					$project-label-height-v1-SMALL;
			$project-label-line-height-v1-LARGE:					$project-label-height-v1-LARGE;*/
/*$project-label-checkradio-color-v1:						$project-label-color-v1;
			$project-label-checkradio-color-v1-ERROR:				$project-label-color-v1-ERROR;
			$project-label-checkradio-color-v1-DISABLED:			$project-label-color-v1-DISABLED;*/
/*$project-label-checkradio-font-size-v1:					$project-default-font-size + 2;
			$project-label-checkradio-font-size-v1-SMALL:			$project-label-checkradio-font-size-v1 - 2;
			$project-label-checkradio-font-size-v1-LARGE:			$project-label-checkradio-font-size-v1 + 2;*/
/*$project-label-checkradio-line-height-v1:				$project-label-checkradio-font-size-v1 + $project-default-line-height-diff;
			$project-label-checkradio-line-height-v1-SMALL:			$project-label-checkradio-font-size-v1-SMALL + $project-default-line-height-diff;
			$project-label-checkradio-line-height-v1-LARGE:			$project-label-checkradio-font-size-v1-LARGE + $project-default-line-height-diff;*/
/*$project-form-text-color-v1: 								$color-gray;
		$project-form-text-color-v1-ERROR:							$color-red;
		$project-form-text-color-v1-DISABLED:						$color-dark-gray;
		$project-form-text-font-family-v1: 							$project-default-font-family;
		$project-form-text-font-size-v1: 							$project-default-font-size;
		$project-form-text-line-height-v1: 							$project-form-text-font-size-v1 + $project-default-line-height-diff;
		$project-form-text-margin-v1: 								10px 0 0 0;*/
/* Import variables END */
/* Import Bootstrap elements START */
/* Import Bootstrap elements END */
/* Import project elements START */
/*** Project mixins ***/
/* mixins START */
/* mixins END */
/*** Project mixins css3 ***/
/* https://github.com/matthieua/sass-css3-mixins */
/* -------------------------------------------------------------
Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets
List of CSS3 Sass Mixins File to be @imported and @included as you need
The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
ENDING css files
note: All CSS3 Properties are being supported by Safari 5
more info: http://www.findmebyip.com/litmus/#css3-properties
Mixins available:
- css3-prefix - arguments: Property, Value
- background-gradient - arguments: Start Color: #3C3C3C, End Color: #999999
- background-radial - arguments: Start Color: #FFFFFF, Start position: 0%, End Color: #000000, End position: 100%
- background-size - arguments: Width: 100%, Height: 100%
- background-opacity - arguments: Color: #000, Opacity: .85
- border-radius - arguments: Radius: 5px
- border-radius-separate - arguments: Top Left: 5px, Top Left: 5px, Bottom Left: 5px, Bottom Right: 5px
- box - arguments: Orientation: horizontal, Pack: center, Align: center
- box-rgba - arguments: R: 60, G: 3, B: 12, Opacity: 0.23, Color: #3C3C3C
- box-shadow - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
- box-sizing - arguments: Type: border-box
- columns - arguments: Count: 3, Gap: 10
- double-borders - arguments: Color One: #3C3C3C, Color Two: #999999, Radius: 0
- flex - arguments: Value: 1
- flip - arguments: ScaleX: -1
- font-face - arguments: Font Family: myFont, File Name: myFont
- opacity - arguments: Opacity: 0.5
- outline radius - arguments: Radius: 5px
- placeholder - arguments: Color: #666666
- resize - arguments: Direction: both
- rotate - arguments: Degree: 0, M11: 0, M12: 0, M21: 0, M22: 0
CSS Matrix Rotation Calculator http://www.boogdesign.com/examples/transforms/matrix-calculator.html
- text-shadow - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
- transform - arguments: Parameters: null
- transform-style - arguments: Style: preserve-3d
- transition - arguments: What: all, Length: 1s, Easing: ease-in-out
- triple-borders - arguments: Color One: #3C3C3C, Color Two: #999999, Color Three: #000000, Radius: 0
- keyframes - arguments: Animation name
- content: Animation css
- animation - arguments: name duration timing-function delay iteration-count direction fill-mode play-state
(http://www.w3schools.com/cssref/css3_pr_animation.asp)
------------------------------------------------------------- */
/*** Project fonts ***/
/* fonts START */
@font-face {
  font-family: "AGaramondPro-Bold";
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.eot");
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.svg#AGaramondPro-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "AGaramondPro-Italic";
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.svg#AGaramondPro-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Light";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Light.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Light.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.svg#Avenir-Light") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Regular";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.svg#Avenir-Next-LT-Pro-Normal-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Regular-Italic";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.svg#Avenir-Next-LT-Pro-Normal-Regular-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Demi";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.svg#Avenir-Next-LT-Pro-Normal-Demi") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Demi-Italic";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.svg#Avenir-Next-LT-Pro-Normal-Demi-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Lora-Regular";
  src: url("../../.././application/themes/base/assets/fonts/Lora-Regular.eot");
  src: url("../../.././application/themes/base/assets/fonts/Lora-Regular.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.svg#Lora-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FontAwesome';
  src: url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.eot");
  src: url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.eot?#iefix") format("embedded-opentype"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.woff") format("woff"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.ttf") format("truetype"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.svg#FontAwesome") format("svg");
  font-weight: normal;
  font-style: normal; }

/* fonts END */
/* Import project elements END */
.footer-menu {
  position: relative; }
  @media (min-width: 992px) {
    .footer-menu {
      margin-right: 30px; } }
  @media (max-width: 767px) {
    .footer-menu {
      width: 100%;
      margin-bottom: 30px; } }
  .footer-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 768px) {
      .footer-menu ul {
        justify-content: space-between; } }
    @media (min-width: 576px) {
      .footer-menu ul {
        align-items: center; } }
    @media (max-width: 767px) {
      .footer-menu ul {
        justify-content: center; } }
    .footer-menu ul li {
      position: relative; }
      @media (max-width: 767px) {
        .footer-menu ul li {
          width: 100%;
          text-align: center; } }
      .footer-menu ul li:before {
        display: none; }
      @media (min-width: 1200px) {
        .footer-menu ul li:first-child {
          padding-left: 0; }
        .footer-menu ul li:last-child {
          padding-right: 0; } }
      @media (min-width: 1620px) {
        .footer-menu ul li {
          padding: 0 15px; } }
      @media (min-width: 1200px) and (max-width: 1619px) {
        .footer-menu ul li {
          padding: 0 10px; } }
      @media (min-width: 992px) and (max-width: 1199px) {
        .footer-menu ul li {
          padding: 0 5px; } }
      @media (max-width: 991px) {
        .footer-menu ul li {
          padding: 0 10px; } }
      .footer-menu ul li a {
        font-family: "Avenir-Next-LT-Pro-Normal-Regular", Arial, Helvetica, sans-serif;
        font-size: 15px;
        line-height: 30px;
        color: #FFFFFF;
        text-transform: uppercase; }
        @media (min-width: 1200px) {
          .footer-menu ul li a {
            position: relative; }
            .footer-menu ul li a:after {
              -webkit-transition: all 0.25s ease;
              -khtml-transition: all 0.25s ease;
              -moz-transition: all 0.25s ease;
              -ms-transition: all 0.25s ease;
              -o-transition: all 0.25s ease;
              transition: all 0.25s ease;
              will-change: transition;
              filter: alpha(opacity=0);
              -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
              -webkit-opacity: 0;
              -khtml-opacity: 0;
              -moz-opacity: 0;
              -ms-opacity: 0;
              -o-opacity: 0;
              opacity: 0;
              content: "";
              display: block;
              position: absolute;
              bottom: -5px;
              left: 50%;
              width: 0;
              height: 2px;
              background-color: #967a7d; }
            .footer-menu ul li a:hover, .footer-menu ul li a:focus {
              text-decoration: none; }
              .footer-menu ul li a:hover:after, .footer-menu ul li a:focus:after {
                filter: alpha(opacity=100);
                -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
                -webkit-opacity: 1;
                -khtml-opacity: 1;
                -moz-opacity: 1;
                -ms-opacity: 1;
                -o-opacity: 1;
                opacity: 1;
                width: 100%;
                left: 0; } }
        @media (min-width: 1200px) {
          .footer-menu ul li a:hover, .footer-menu ul li a:focus {
            color: #e4cdc7; } }
