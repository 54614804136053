/* Import variables START */
/*$project-form-control-background-color-v1:					$color-light-gray;
		$project-form-control-background-color-v1-AUTOFILL:			$project-form-control-background-color-v1;
		$project-form-control-background-color-v1-FOCUS:			$color-light-gray;
		$project-form-control-background-color-v1-ERROR:			$color-light-gray;
		$project-form-control-background-color-v1-DISABLED:			$color-light-gray;*/
/*$project-form-control-border-color-v1:						$color-gray;
		$project-form-control-border-color-v1-FOCUS:				$color-gray;
		$project-form-control-border-color-v1-ERROR:				$color-red;
		$project-form-control-border-color-v1-DISABLED:				$color-gray;*/
/*$project-form-control-border-radius-v1:						$project-default-border-radius;
		$project-form-control-border-radius-v1-SMALL:				$project-form-control-border-radius-v1 - 5;
		$project-form-control-border-radius-v1-LARGE:				$project-form-control-border-radius-v1 + 5;*/
/*$project-form-control-border-width-v1:						1px;
		$project-form-control-border-width-v1-SMALL:				$project-form-control-border-width-v1;
		$project-form-control-border-width-v1-LARGE:				$project-form-control-border-width-v1;*/
/*$project-form-control-color-v1:								$color-gray;
		$project-form-control-color-v1-AUTOFILL:					$project-form-control-color-v1;
		$project-form-control-color-v1-FOCUS:						$color-black;
		$project-form-control-color-v1-ERROR:						$color-red;
		$project-form-control-color-v1-DISABLED:					$color-dark-gray;

		$project-form-control-color-v1-placeholder:					$color-gray;
		$project-form-control-color-v1-placeholder-FOCUS:			$color-black;
		$project-form-control-color-v1-placeholder-ERROR:			$color-red;
		$project-form-control-color-v1-placeholder-DISABLED:		$color-dark-gray;*/
/*$project-form-control-font-size-v1:							$project-default-font-size - 2;
		$project-form-control-font-size-v1-SMALL:					$project-form-control-font-size-v1 - 2;
		$project-form-control-font-size-v1-LARGE:					$project-form-control-font-size-v1 + 2;*/
/*$project-form-control-height-v1: 							$project-button-height-v1;
		$project-form-control-height-v1-SMALL: 						$project-button-height-v1-SMALL;
		$project-form-control-height-v1-LARGE: 						$project-button-height-v1-LARGE;*/
/*$project-form-control-line-height-v1:						$project-form-control-height-v1;
		$project-form-control-line-height-v1-SMALL:					$project-form-control-height-v1-SMALL;
		$project-form-control-line-height-v1-LARGE:					$project-form-control-height-v1-LARGE;*/
/*$project-form-control-padding-v1:							0 15px;
		$project-form-control-padding-v1-SMALL:						0 10px;
		$project-form-control-padding-v1-LARGE:						0 20px;*/
/*$project-form-control-padding-textarea-v1:					10px 15px;
		$project-form-control-padding-textarea-v1-SMALL:			 5px 10px;
		$project-form-control-padding-textarea-v1-LARGE:			15px 20px;*/
/*$project-select-skin-background-image-v1:					'../img/icons/select-skin-arrow-v1.svg';
		$project-select-skin-background-size-x-v1:					14px;
		$project-select-skin-background-size-y-v1:					auto;
		$project-select-skin-background-size-x-v1-SMALL:			12px;
		$project-select-skin-background-size-y-v1-SMALL:			auto;
		$project-select-skin-background-size-x-v1-LARGE:			16px;
		$project-select-skin-background-size-y-v1-LARGE:			auto;
		$project-select-skin-background-position-v1:				22px;
		$project-select-skin-background-position-v1-SMALL:			20px;
		$project-select-skin-background-position-v1-LARGE:			26px;*/
/*$project-checkradio-background-color-v1:					$color-yellow;
		$project-checkradio-background-color-v1-FOCUS:				$color-orange;
		$project-checkradio-background-color-v1-CHECKED:			$color-yellow;
		$project-checkradio-background-color-v1-ERROR:				$color-yellow;
		$project-checkradio-background-color-v1-DISABLED:			$color-white;*/
/*$project-checkradio-border-color-v1:						$color-green;
		$project-checkradio-border-color-v1-FOCUS:					$color-dark-green;
		$project-checkradio-border-color-v1-CHECKED:				$color-dark-green;
		$project-checkradio-border-color-v1-ERROR:					$color-dark-red;
		$project-checkradio-border-color-v1-DISABLED:				$color-light-gray;*/
/*$project-checkradio-inside-color-v1:						$color-dark-green;
		$project-checkradio-inside-color-v1-FOCUS:					$color-green;
		$project-checkradio-inside-color-v1-CHECKED:				$color-green;
		$project-checkradio-inside-color-v1-ERROR:					$color-dark-red;
		$project-checkradio-inside-color-v1-DISABLED:				$color-dark-gray;*/
/*$project-label-color:										$project-default-color;
		$project-label-color-ERROR:									$color-red;
		$project-label-color-DISABLED:								$color-gray;

		// font family
		$project-label-font-family:									$project-default-font-family;

		// font size
		$project-label-font-size:									$project-default-font-size;
		$project-label-font-size-SMALL:								$project-label-font-size - 2;
		$project-label-font-size-LARGE:								$project-label-font-size + 2;

		// height
		$project-label-height:		 								$project-form-control-height;
		$project-label-height-SMALL: 								$project-form-control-height-SMALL;
		$project-label-height-LARGE: 								$project-form-control-height-LARGE;

		// line height
		$project-label-line-height:									$project-label-height;
		$project-label-line-height-SMALL:							$project-label-height-SMALL;
		$project-label-line-height-LARGE:							$project-label-height-LARGE;*/
/*$project-label-color-v1:								$color-green;
			$project-label-color-v1-ERROR:							$color-dark-red;
			$project-label-color-v1-DISABLED:						$color-light-gray;*/
/*$project-label-line-height-v1:							$project-label-height-v1;
			$project-label-line-height-v1-SMALL:					$project-label-height-v1-SMALL;
			$project-label-line-height-v1-LARGE:					$project-label-height-v1-LARGE;*/
/*$project-label-checkradio-color-v1:						$project-label-color-v1;
			$project-label-checkradio-color-v1-ERROR:				$project-label-color-v1-ERROR;
			$project-label-checkradio-color-v1-DISABLED:			$project-label-color-v1-DISABLED;*/
/*$project-label-checkradio-font-size-v1:					$project-default-font-size + 2;
			$project-label-checkradio-font-size-v1-SMALL:			$project-label-checkradio-font-size-v1 - 2;
			$project-label-checkradio-font-size-v1-LARGE:			$project-label-checkradio-font-size-v1 + 2;*/
/*$project-label-checkradio-line-height-v1:				$project-label-checkradio-font-size-v1 + $project-default-line-height-diff;
			$project-label-checkradio-line-height-v1-SMALL:			$project-label-checkradio-font-size-v1-SMALL + $project-default-line-height-diff;
			$project-label-checkradio-line-height-v1-LARGE:			$project-label-checkradio-font-size-v1-LARGE + $project-default-line-height-diff;*/
/*$project-form-text-color-v1: 								$color-gray;
		$project-form-text-color-v1-ERROR:							$color-red;
		$project-form-text-color-v1-DISABLED:						$color-dark-gray;
		$project-form-text-font-family-v1: 							$project-default-font-family;
		$project-form-text-font-size-v1: 							$project-default-font-size;
		$project-form-text-line-height-v1: 							$project-form-text-font-size-v1 + $project-default-line-height-diff;
		$project-form-text-margin-v1: 								10px 0 0 0;*/
/* Import variables END */
/* Import Bootstrap elements START */
/* Import Bootstrap elements END */
/* Import project elements START */
/*** Project mixins ***/
/* mixins START */
/* mixins END */
/*** Project mixins css3 ***/
/* https://github.com/matthieua/sass-css3-mixins */
/* -------------------------------------------------------------
Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets
List of CSS3 Sass Mixins File to be @imported and @included as you need
The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
ENDING css files
note: All CSS3 Properties are being supported by Safari 5
more info: http://www.findmebyip.com/litmus/#css3-properties
Mixins available:
- css3-prefix - arguments: Property, Value
- background-gradient - arguments: Start Color: #3C3C3C, End Color: #999999
- background-radial - arguments: Start Color: #FFFFFF, Start position: 0%, End Color: #000000, End position: 100%
- background-size - arguments: Width: 100%, Height: 100%
- background-opacity - arguments: Color: #000, Opacity: .85
- border-radius - arguments: Radius: 5px
- border-radius-separate - arguments: Top Left: 5px, Top Left: 5px, Bottom Left: 5px, Bottom Right: 5px
- box - arguments: Orientation: horizontal, Pack: center, Align: center
- box-rgba - arguments: R: 60, G: 3, B: 12, Opacity: 0.23, Color: #3C3C3C
- box-shadow - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
- box-sizing - arguments: Type: border-box
- columns - arguments: Count: 3, Gap: 10
- double-borders - arguments: Color One: #3C3C3C, Color Two: #999999, Radius: 0
- flex - arguments: Value: 1
- flip - arguments: ScaleX: -1
- font-face - arguments: Font Family: myFont, File Name: myFont
- opacity - arguments: Opacity: 0.5
- outline radius - arguments: Radius: 5px
- placeholder - arguments: Color: #666666
- resize - arguments: Direction: both
- rotate - arguments: Degree: 0, M11: 0, M12: 0, M21: 0, M22: 0
CSS Matrix Rotation Calculator http://www.boogdesign.com/examples/transforms/matrix-calculator.html
- text-shadow - arguments: X: 2px, Y: 2px, Blur: 5px, Color: rgba(0,0,0,.4)
- transform - arguments: Parameters: null
- transform-style - arguments: Style: preserve-3d
- transition - arguments: What: all, Length: 1s, Easing: ease-in-out
- triple-borders - arguments: Color One: #3C3C3C, Color Two: #999999, Color Three: #000000, Radius: 0
- keyframes - arguments: Animation name
- content: Animation css
- animation - arguments: name duration timing-function delay iteration-count direction fill-mode play-state
(http://www.w3schools.com/cssref/css3_pr_animation.asp)
------------------------------------------------------------- */
/*** Project fonts ***/
/* fonts START */
@font-face {
  font-family: "AGaramondPro-Bold";
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.eot");
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Bold.svg#AGaramondPro-Bold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "AGaramondPro-Italic";
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/AGaramondPro-Italic.svg#AGaramondPro-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Light";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Light.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Light.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Light.svg#Avenir-Light") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Regular";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular.svg#Avenir-Next-LT-Pro-Normal-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Regular-Italic";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Regular-Italic.svg#Avenir-Next-LT-Pro-Normal-Regular-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Demi";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi.svg#Avenir-Next-LT-Pro-Normal-Demi") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Avenir-Next-LT-Pro-Normal-Demi-Italic";
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.eot");
  src: url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Avenir-Next-LT-Pro-Normal-Demi-Italic.svg#Avenir-Next-LT-Pro-Normal-Demi-Italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Lora-Regular";
  src: url("../../.././application/themes/base/assets/fonts/Lora-Regular.eot");
  src: url("../../.././application/themes/base/assets/fonts/Lora-Regular.eot?#iefix") format("embedded-opentype"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.woff") format("woff"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.ttf") format("truetype"), url("../../.././application/themes/base/assets/fonts/Lora-Regular.svg#Lora-Regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FontAwesome';
  src: url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.eot");
  src: url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.eot?#iefix") format("embedded-opentype"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.woff") format("woff"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.ttf") format("truetype"), url("../../../node_modules/font-awesome/fonts/fontawesome-webfont.svg#FontAwesome") format("svg");
  font-weight: normal;
  font-style: normal; }

/* fonts END */
/* Import project elements END */
.main .page-share {
  margin-top: 100px;
  padding-top: 40px;
  border-top: 1px solid #e4cdc7; }
  .main .page-share ul {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none; }
    .main .page-share ul li {
      display: block;
      margin: 5px; }
      .main .page-share ul li a {
        display: block;
        width: 55px;
        height: 55px;
        line-height: 57px;
        border: 1px solid #e4cdc7;
        text-align: center;
        -webkit-border-top-left-radius: 50%;
        -webkit-border-top-right-radius: 50%;
        -webkit-border-bottom-right-radius: 50%;
        -webkit-border-bottom-left-radius: 50%;
        -moz-border-radius-topleft: 50%;
        -moz-border-radius-topright: 50%;
        -moz-border-radius-bottomright: 50%;
        -moz-border-radius-bottomleft: 50%;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%; }
        @media (min-width: 1200px) {
          .main .page-share ul li a:hover, .main .page-share ul li a:focus {
            background-color: #e4cdc7;
            color: #FFFFFF; } }
